import jQuery from "jquery";
import popper from "popper.js";
import bootstrap from "bootstrap";
import 'owl.carousel2';
import '../vendor/jquery.maskedinput.min.js';
import 'jquery.scrollbar';
import Scrollbar from 'smooth-scrollbar';
import SmoothScroll from 'smooth-scroll';
import Swiper from 'swiper';
import '@fancyapps/fancybox';


jQuery(function () {
	jQuery(document).ready(function () {
		// Отправляет данные из формы на сервер и получает ответ
		jQuery('#feedbackForm').on('submit', function (event) {

			event.preventDefault();

			var form = $('#feedbackForm'),
				button = $('#feedbackFormButton'),
				answer = $('#answer'),
				loader = $('#loader');

			jQuery.ajax({
				url: 'vendor/handler.php',
				type: 'POST',
				data: form.serialize(),
				beforeSend: function () {
					answer.empty();
					button.attr('disabled', true);
					loader.fadeIn();
				},
				success: function (result) {
					loader.fadeOut(300, function () {
						answer.text(result);
					});
					form.find('.field').val('');
					button.attr('disabled', false);
				},
				error: function () {
					loader.fadeOut(300, function () {
						answer.text('Произошла ошибка! Попробуйте позже.');
					});
					button.attr('disabled', false);
				}
			});

		});
		jQuery(function () {

			jQuery('.main-slider').owlCarousel({
				items: 1,
				loop: true,
				autoplay: true,
				smartSpeed: 1500,
				responsive: {
					0: {
						items: 1,
						dots: false
					},
					768: {
						dots: true
					},
				}
			});

			jQuery('nav a[href^="/' + location.pathname.split("/")[1] + '"]').addClass('active');

			$(function() {
				var owl = $('.gallery-slider'),

					owlOptions = {
					loop: false,
					margin: 0,
					smartSpeed: 700,
					nav: true,
					items: 1,
					dots: false,
					navText : ["<img src='img/mobile-slider-arrow-left.svg' alt=''>","<img src='img/mobile-slider-arrow-right.svg' alt=''>"]
					};

				if ( $(window).width() < 460 ) {
					var owlActive = owl.owlCarousel(owlOptions);
				} else {
					owl.addClass('off');
				}

				$(window).resize(function() {
					if ( $(window).width() < 460 ) {
						if ( $('.owl-carousel').hasClass('off') ) {
							var owlActive = owl.owlCarousel(owlOptions);
							owl.removeClass('off');
						}
					} else {
						if ( !$('.owl-carousel').hasClass('off') ) {
							owl.addClass('off').trigger('destroy.owl.carousel');
							owl.find('.owl-stage-outer').children(':eq(0)').unwrap();
						}
					}
				});
			});

			jQuery('#loader2').css('display', 'none');
		});	

		var scroll = new SmoothScroll('a[href*="#"]');

		  $('.second-button').on('click', function () {
		
			$('.animated-icon2').toggleClass('open');
		  });
	});
});

